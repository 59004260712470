import ApiService from "@/core/services/api.service";
import SanctumService from "@/core/services/sanctum.service";
// import store from "@/core/services/store";
import { UPDATE_PERSONAL_INFO, UPDATE_ADMIN_INFO } from "@/core/services/store/profile.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const IMPERSONATE = "inpersonate"
export const END_IMPERSONATION = 'end_inpersonate'
export const UPDATE_AUTH = "update_auth";
export const LOGOUT = "auth/logout";
export const REGISTER = "signup";
export const UPDATE_PASSWORD = "updateUser";
export const SET_COOKIE = "setCookie";
export const CLAIM_BUSINESS = 'claim_business';

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const FORGOT_PASSWORD = "forgotPassword";
export const RESET_PASSWORD = "resetPassword";
export const SET_ERROR = "setError";
export const SET_IMPERSONATING = "setImpersonating"
const state = {
  errors: null,
  user: {},
  isAuthenticated: false,
  normal: false,
  currency: "£",
  isImpersonating: sessionStorage.getItem('is_impersonating') === 'true',
};

const getters = {
  isImpersonating(state) {
    return state.isImpersonating
  },
  currentUser(state) {
    // var userAuth = sessionStorage.getItem('USER_STATE');
    // if (userAuth) {

    //   let authParsed = JSON.parse(userAuth);
    //   return authParsed.user;
    // }
    return state.user;
  },
  isAuthenticated(state) {
    // let userAuth = sessionStorage.getItem('USER_STATE');
    // if (userAuth) {

    //   let authParsed = JSON.parse(userAuth);
    //   return authParsed.isAuthenticated;
    // }
    return state.isAuthenticated;
  }
};

const actions = {

  [UPDATE_AUTH](context) {
    let userAuth = sessionStorage.getItem('USER_STATE');
    if (userAuth) {
      let authParsed = JSON.parse(userAuth);

      context.commit(SET_AUTH, authParsed);
    }
  },
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post(LOGIN, credentials)
        .then(({ data }) => {
          console.log('data.data' + JSON.stringify(data.data));
          if (data.data.token) {
            sessionStorage.setItem('is_superAdmin', data.data.orders == '-1' ? 1 : 0)
            console.log('is_superAdmin', data.data.orders == '-1' ? 1 : 0)
            sessionStorage.setItem("ID_TOKEN", data.data.token);
            sessionStorage.setItem("BRANCHES_COUNT", data.data.user.branches_count);
            sessionStorage.setItem("SELECTED_BRANCH", 0);
            const main_branch = data.data.user.business;
            sessionStorage.setItem("MAIN_BRANCH", JSON.stringify(main_branch));
          }
          context.dispatch(VERIFY_AUTH);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response)
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [IMPERSONATE](context, userId) {
    return new Promise((resolve, reject) => {
      ApiService.post('/login', { impersonate_user_id: userId })
        .then(({ data }) => {
          console.log('Impersonation response data:', JSON.stringify(data));

          if (data.data.token) {
            // Store impersonation token and flag
            sessionStorage.setItem('impersonation_token', data.data.token);
            sessionStorage.setItem('is_impersonating', 'true');
            console.log('Impersonation Token Set');

            console.log('BRANCHES_COUNT', data.data.user.branches_count);
            sessionStorage.setItem('is_superAdmin', data.data.orders == '-1' ? 1 : 0)
            console.log('is_superAdmin', data.data.orders == '-1' ? 1 : 0)
            sessionStorage.setItem('ADMIN_TOKEN', sessionStorage.getItem('ID_TOKEN'));

            sessionStorage.setItem("ID_TOKEN", data.data.token);
            sessionStorage.setItem("BRANCHES_COUNT", data.data.user.branches_count);
            sessionStorage.setItem("SELECTED_BRANCH", 0);
            const main_branch = data.data.user.business;
            sessionStorage.setItem("MAIN_BRANCH", JSON.stringify(main_branch));
            context.commit(SET_IMPERSONATING, true);


            // Update the user state
            // context.commit('SET_USER', data.data.user);

            // Dispatch auth verification if needed
            context.dispatch(VERIFY_AUTH);
            resolve(data.data);

          }
        })
        .catch(({ response }) => {
          console.error('Impersonation error:', response);
          context.commit('SET_ERROR', response.data.errors);
          reject(response);
        });
    });
  },
  [END_IMPERSONATION](context) {
    return new Promise((resolve, reject) => {
      // ApiService.post('/end-impersonation') // Adjust the endpoint as needed
      //   .then(() => {
      // Remove impersonation-related data from session storage
      sessionStorage.removeItem('impersonation_token');
      sessionStorage.removeItem('is_impersonating');
      sessionStorage.removeItem('is_superAdmin');
      sessionStorage.removeItem('MAIN_BRANCH');
      sessionStorage.removeItem('BRANCHES_COUNT');
      sessionStorage.removeItem('SELECTED_BRANCH');
      sessionStorage.setItem('ID_TOKEN', sessionStorage.getItem('ADMIN_TOKEN')); // Restore the original token

      context.dispatch(VERIFY_AUTH);
      context.commit(SET_IMPERSONATING, false);


      resolve("sss");


    });
  },

  [CLAIM_BUSINESS](context, form) {
    return new Promise((resolve, reject) => {
      ApiService.post('claim-your-business', form)
        .then(({ data }) => {
          console.log(data.data);

          resolve(data);
        })
        .catch(({ response }) => {
          reject(response)
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise(resolve => {
      ApiService.post(LOGOUT)
        .then(({ data }) => {
          context.commit(PURGE_AUTH);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post(REGISTER, credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    console.log("before verify");

    ApiService.get("user")
      .then(data => {
        console.log("after verify");

        // Determine login type
        const loginType = data.data.orders == -1 ? false : true;

        // Update isImpersonating based on the orders value


        // state.isImpersonating = data.data.orders == 1 ? false : true;
        //

        // Create userAuth object
        const userAuth = {
          errors: {},
          user: data.data,
          isAuthenticated: true,
          normal: data.data.orders == -1 ? false : true,
          currency: data.data.currency,
        };

        // Dispatch appropriate actions based on loginType
        if (loginType) {
          context.dispatch(UPDATE_PERSONAL_INFO);
        } else {
          context.dispatch(UPDATE_ADMIN_INFO, data.data);
        }

        // Commit authentication state
        context.commit(SET_AUTH, userAuth);

        // Update sessionStorage with userAuth data
        sessionStorage.setItem('USER_STATE', JSON.stringify(userAuth));

      })
      .catch(response => {
        console.log("later verify");
        context.commit(SET_ERROR, 'Unauthorized');
        context.commit(PURGE_AUTH);
      });
  },

  [SET_COOKIE]() {
    ApiService.setCookie();
  },
  [UPDATE_PASSWORD](context, payload) {

    return ApiService.post("password", payload).then(({ data }) => {
      context.commit(SET_PASSWORD, data.data);
      return data;
    });
  },

  [FORGOT_PASSWORD](context, payload) {
    const email = payload;

    return ApiService.post("forgot-password", email).then(({ data }) => {
      return data;
    });
  },

  [RESET_PASSWORD](context, payload) {
    const email = payload;

    return ApiService.post("reset-password", email).then(({ data }) => {
      return data;
    });
  }

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_IMPERSONATING](state, bol) {
    state.isImpersonating = bol;
  },

  [SET_AUTH](state, user) {
    state.isAuthenticated = user.isAuthenticated;
    state.user = user.user;
    state.errors = {};
    state.normal = user.normal
    state.currency = user.currency;
    // if (user.token) {
    //   sessionStorage.setItem("ID_TOKEN" , user.token)
    // }
    //state = user;
  },
  // [SET_AUTH_FROM_SESSION](state, payload) {
  //   state = payload;
  // },

  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    state.normal = false;
    sessionStorage.removeItem('personalInfo');
    sessionStorage.clear();
    localStorage.clear();

    sessionStorage.setItem('USER_STATE', JSON.stringify(state));
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
